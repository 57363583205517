import "./App.css";
import under from "./constr.jpg";

function App() {
  return (
    <div className="App">
      <img src={under} alt="logo" className="construction" />
    </div>
  );
}

export default App;
